import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootSaga from "../saga/saga";

import { rootReducer } from "../reducers";

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
    collapsed: true,
});

let middleware = [];

if (process.env.NODE_ENV === "development") {
    middleware = [...middleware, logger, sagaMiddleware];
} else {
    middleware = [...middleware, sagaMiddleware];
}

const store = createStore(persistedReducer, applyMiddleware(...middleware));
const presistorStore = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, presistorStore };
