import React, { memo, Fragment, useState, useEffect } from "react";
import { FormGroup, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Spinner } from "reactstrap";
import classNames from "classnames";
import isEqual from "lodash/isEqual";

import usePrevious from "../../common/helper/usePrevious";

const DropdownInputBox = (props) => {
	const [toggleOpen, setToggleOpen] = useState(false);
	const [dropdownSelected, setDropdownSelected] = useState("");
	const previousData = usePrevious(props.data);
	const previousValue = usePrevious(props.value);
	const dropdownSectionClasses = classNames({
		"c-dropdown-input": true,
		"c-dropdown-input--disabled": props.disabled,
		"c-dropdown-input--invalid": props.invalid,
	});
	const dropdownToggleClasses = classNames({
		"c-dropdown-input__toggle": true,
		"c-dropdown-input__toggle--selected": dropdownSelected,
	});

	useEffect(() => {
		if (!isEqual(previousData, props.data)) {
			if ((props.defaultValue && props.data.length) || (props.defaultValue === 0 && props.data.length)) {
				const selectedItem = props.data.filter((insurer) => insurer.value === props.defaultValue);
				if (selectedItem[0]) setDropdownSelected(selectedItem[0].label);
			}
		}
	}, [props.data, props.defaultValue, previousData]);

	useEffect(() => {
		if (!props.value && !isEqual(previousValue, props.value)) {
			setDropdownSelected("");
		}
		if (props.value && !isEqual(previousValue, props.value)) {
			const selectedItem = props.data.filter((insurer) => insurer.value === props.value);
			if (selectedItem[0]) setDropdownSelected(selectedItem[0].label);
		}
	}, [props.value, props.data, previousValue]);

	const toggleDropdown = (event) => {
		const value = event.currentTarget.value;
		const selectedItem = event.currentTarget.textContent;

		if (!props.disabled && props.data.length) {
			setToggleOpen(!toggleOpen);

			setDropdownSelected(selectedItem);

			let selectedValue = value;

			if (value === "") {
				const matchingItem = previousData.find((item) => item.label.includes(selectedItem));
				
				if (matchingItem) {
					selectedValue = matchingItem.value;
				}
			}

			props.onSelected(props.name, selectedValue);
		}
	};

	const DropdownMenuItem = () => {
		if (props.disabled && !props.data.length) return null;
		return props.data.map((item, index) => {
			return (
				<DropdownItem className="c-dropdown-input__item" value={item.value} key={`${item.value}-${index}`}>
					{item.label}
				</DropdownItem>
			);
		});
	};

	const DropdownToggleItem = () => {
		return (
			<DropdownToggle caret className={dropdownToggleClasses}>
				{!props.data.length && props.loading ? (
					<div className="c-dropdown-input__loading">
						<Spinner type="grow" size="sm" />
						<p className="c-dropdown-input__loading-text">Loading ...</p>
					</div>
				) : (
					dropdownSelected || props.placeholder
				)}
			</DropdownToggle>
		);
	};

	return (
		<Fragment>
			{props.label && props.labelOut && (
				<Label className="c-dropdown-input__label mb-2" for={props.label.toLowerCase()}>
					{props.label}
				</Label>
			)}
			<FormGroup className={dropdownSectionClasses}>
				{props.label && !props.labelOut && (
					<Label className="c-dropdown-input__label" for={props.label.toLowerCase()}>
						{props.label}
					</Label>
				)}
				<Input hidden readOnly type="text" name={props.name} value={dropdownSelected} id={props.label.toLowerCase()} />
				<Dropdown isOpen={toggleOpen} toggle={toggleDropdown}>
					<DropdownToggleItem />
					<DropdownMenu
						className={`c-dropdown-input__menu
                        ${props.double ? "c-dropdown-input__menu-double" : ""}
                        `}
					>
						<DropdownMenuItem />
					</DropdownMenu>
				</Dropdown>
			</FormGroup>
			{!props.hideError && <ErrorMessage errorMessage={props.errorMessage} />}
		</Fragment>
	);
};

const memoErrorMessage = ({ errorMessage }) => {
	return <p className="c-dropdown-input__error-msg">{errorMessage}</p>;
};

const ErrorMessage = memo(memoErrorMessage);

DropdownInputBox.defaultProps = {
	data: [],
	disabled: false,
	loading: true,
	onSelected: () => {},
	labelOut: false,
	hideError: false,
};

export default DropdownInputBox;

export const HorizontalDropdownInput = (props) => {
	return (
		<div className="c-input-horizontal">
			<p className="label">
				<span>{props.label}</span>
				{props.required && <span className="text-danger">*</span>}
			</p>
			<span className="separator">:</span>
			<div className="c-input__field">
				<DropdownInputBox {...props} label={null} />
			</div>
		</div>
	);
};

HorizontalDropdownInput.defaultProps = {
	data: [],
	disabled: false,
	loading: true,
	onSelected: () => {},
};
