import dateRangeListActions from "../actions/date-range-list-actions";

const initialState = {};

const dateRangeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case dateRangeListActions.UPDATE_DATE_RANGE:
            return { ...action.data };
        default:
            return state;
    }
};

export default dateRangeListReducer;
