import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import usersListActions from "../actions/users-list-actions";

function* getUsersList(action) {
    try {
        const response = yield call(apiRequest.admin.get.usersList, action.data);
        yield put({ type: usersListActions.UPDATE_USERS_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* usersListSaga() {
    yield takeLatest(usersListActions.GET_USERS_LIST, getUsersList);
}

export default usersListSaga;
