import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import carsMakeList from "../actions/cars-make-list-actions";

function* getCarsMakeList(action) {
    try {
        const response = yield call(apiRequest.admin.get.carMakeLists, action.data);
        yield put({ type: carsMakeList.UPDATE_CARS_MAKE_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* carsMakeListSaga() {
    yield takeLatest(carsMakeList.GET_CARS_MAKE_LIST, getCarsMakeList);
}

export default carsMakeListSaga;
