import commissionActions from '../actions/commission-actions';

const initialState = {
    commissions: [],
	isLoading: false,
};

const commissionReducer = (state = initialState, action) => {
    switch (action.type) {
	case commissionActions.UPDATE_COMMISSION:
		return {
			...state,
			...action.data,
		};

	case commissionActions.COMMISSION_IS_LOADING:
		return {
			...state,
			isLoading: action.isLoading,
		};

	default:
		return state;
    }
};

export default commissionReducer;