import React, { memo } from "react";
import classNames from "classnames";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const SwitchLanguage = (props) => {
    const switchLang = lang => {
        i18n.use(initReactI18next).init({ lng: lang });
    };
    const langSwitchClasses = classNames({
        "c-lang-switch": true,
        "c-lang-switch--black": props.black,
    });

    return (
        <div className={langSwitchClasses}>
            <p className="c-lang-switch__text" tabIndex={-1} onClick={() => switchLang("en")}>
                EN
            </p>
            /
            <p className="c-lang-switch__text" tabIndex={-2} onClick={() => switchLang("bm")}>
                BM
            </p>
        </div>
    );
};

export default memo(SwitchLanguage);
