import affiliateActions from "../actions/affiliate-actions";

const initialState = {
    profileNotReady: false,
    profile: {},
};

const affiliateReducer = (state = initialState, action) => {
    switch (action.type) {
        case affiliateActions.AFFILIATE_PROFILE_NOT_READY:
            return {
                ...state,
                profileNotReady: true
            };

        case affiliateActions.UPDATE_AFFILIATE_PROFILE:
            return {
                ...state,
                profileNotReady: false,
                ...action.data,
            };

        case affiliateActions.UPDATE_AFFILIATE_PROFILE_PIC:
        case affiliateActions.UPDATE_AFFILIATE_LOGO_PIC:
        case affiliateActions.UPDATE_AFFILIATE_BANNER_PIC:

        case affiliateActions.UPDATE_REPORT_MARGIN:
        case affiliateActions.REPORT_MARGIN_IS_LOADING:
            return {
                ...state,
                ...action.data,
            };

        default:
            return state;
    }
};

export default affiliateReducer;