import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import userActions from "../redux/actions/user-actions";
import isLogin from "../common/helper/isLogin";

const PublicRoute = ({ 
    component: Component, 
    restricted, 
    ...rest 
}) => {
    const dispatch = useDispatch();
    const [auth, setAuth] = useState(null);
    const globalState = useSelector(state => state);
    const adminRole = globalState.user.profile.roleName === "Admin";

    useEffect(
        () => {async function validateToken() {
                const validToken = await isLogin();
                if (!validToken) dispatch({ type: userActions.USER_LOGOUT });
                setAuth(validToken);
            }

            validateToken();
        },
        [dispatch]
    );

    if (auth === null) return <div />;
    
    return <Route {...rest} 
        render={props => (auth ? 
            <Redirect to={adminRole ? '/admin/dashboard' : '/affiliate/dashboard'} /> : 
            <Component {...props} />
        )} 
    />;
};

export default PublicRoute;
