import React, { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "react-toggle/style.css";

ReactDOM.render(
    <StrictMode>
        <Suspense fallback={null}>
            <App />
        </Suspense>
    </StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
