import stateListActions from "../actions/state-list-actions";

const initialState = {};

const stateListReducer = (state = initialState, action) => {
    switch (action.type) {
        case stateListActions.UPDATE_STATE_LIST:
            const { states } = action.data;
            const data = states.reduce ( ( result, item, index ) => {
                result[index] = {
                    value: item.name,
                    label: item.name
                };
                return result;
            }, [] );
            return { data };

        case stateListActions.CLEAR_STATE_LIST:
            return { data: null };
            
        default:
            return state;
    }
};

export default stateListReducer;