import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import insurersActions from "../actions/insurer-actions";

function* getInsurerLists(action) {
    try {
        const response = yield call(apiRequest.admin.get.insurerLists, action.data);
        yield put({ type: insurersActions.UPDATE_INSURER_LISTS, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* insurersSaga() {
    yield takeLatest(insurersActions.GET_INSURERS_LIST, getInsurerLists);
}

export default insurersSaga;
