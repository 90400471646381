import { call, put, takeLatest } from "redux-saga/effects";
import apiRequest from "../../common/services/api";
import commissionActions from "../actions/commission-actions";

function* getCommissions() {
    try {
        yield put({
            type: commissionActions.COMMISSION_IS_LOADING,
            isLoading: true
        });
        let response = yield call(apiRequest.admin.get.getCommissions);
        const {
            affiliateCommissions
        } = response.data;
        yield put({ 
            type: commissionActions.UPDATE_COMMISSION, 
            data: {
				commissions: affiliateCommissions
			}
        });
        yield put({
            type: commissionActions.COMMISSION_IS_LOADING,
            isLoading: false
        });
    } catch (error) {
        console.log(error);
    }
}

function* commissionSaga() {
    yield takeLatest(commissionActions.GET_COMMISSION, getCommissions);
}

export default commissionSaga;
