import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import countryListActions from "../actions/country-list-actions";

function* getCountryList() {
    try {
        const response = yield call(apiRequest.general.get.getCountries);
        yield put({ type: countryListActions.UPDATE_COUNTRY_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* countryListSaga() {
    yield takeLatest(countryListActions.GET_COUNTRY_LIST, getCountryList);
}

export default countryListSaga;