import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import carsModelListActions from "../actions/cars-model-list-action";

function* getCarsModelList(action) {
    try {
        const response = yield call(apiRequest.admin.get.modelLists, action.data);
        yield put({ type: carsModelListActions.UPDATE_CARS_MODEL_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* carsModelListSaga() {
    yield takeLatest(carsModelListActions.GET_CARS_MODEL_LIST, getCarsModelList);
}

export default carsModelListSaga;
