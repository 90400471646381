import React, { Fragment, useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import classNames from "classnames";
import useIsMount from "../../common/helper/useIsMount";

import Tick_Icon from "../../assets/images/tick.png";

const TickBoxInput = (props) => {
    const isMount = useIsMount();
    const [checkedLists, setCheckedLists] = useState({});
    const tickboxSectionClasses = classNames({
        "c-tickbox-input": true,
        "c-tickbox-input--disabled": props.disabled,
        "c-tickbox-input c-tickbox-input--invalid": props.invalid,
    });

    useEffect(() => {
        if (isMount) {
            setCheckedLists(props.defaultValue);
        }
    }, [isMount, props.defaultValue]);

    const onCheckList = (event) => {
        const value = event.currentTarget.dataset.value;
        const tickValue = {
            ...checkedLists,
            [value]: !checkedLists[value],
        };
        setCheckedLists(tickValue);

        props.onChecked(props.name, tickValue);
    };

    const ErrorMessage = () => {
        const errorMessage = props.invalid && props.errorMessage ? props.errorMessage : "";
        return <p className="c-tickbox-input__error-msg">{errorMessage}</p>;
    };

    return (
        <Fragment>
            <FormGroup className={tickboxSectionClasses}>
                <Input
                    hidden
                    readOnly
                    type="text"
                    name={props.name}
                    value={JSON.stringify(checkedLists)}
                    onKeyUp={props.onKeyUp}
                />
                <Label className="c-tickbox-input__label" for={props.label.toLowerCase()}>
                    {props.label}
                </Label>
                <ul className="c-tickbox-input__lists">
                    {props.dataLists.map((list, index) => {
                        const key = `${list.value}_${index}`;
                        return (
                            <li key={key} className="c-tickbox-input__item" onClick={onCheckList} data-value={list.value}>
                                <div className="c-tickbox-input__checkbox">
                                    <img
                                        className={checkedLists[list.value] ? "c-tickbox-input__checked" : ""}
                                        src={Tick_Icon}
                                        alt={list.value}
                                    />
                                </div>
                                <p className="c-tickbox-input__text">{list.label}</p>
                            </li>
                        );
                    })}
                </ul>
            </FormGroup>
            <ErrorMessage />
        </Fragment>
    );
};

TickBoxInput.defaultProps = {
    label: "label",
    placeholder: "",
    dataLists: [],
    defaultValue: "",
    onChecked: () => {},
};

export default TickBoxInput;
