import { call, put, takeLatest } from "redux-saga/effects";
import apiRequest from "../../common/services/api";
import bonusActions from "../actions/bonus-actions";

function* getBonuses() {
    try {
        yield put({
            type: bonusActions.BONUS_IS_LOADING,
            isLoading: true
        });
        let response = yield call(apiRequest.admin.get.getBonuses);
        const {
            affiliateBonuses
        } = response.data;
        yield put({ 
            type: bonusActions.UPDATE_BONUS, 
            data: {
				bonuses: affiliateBonuses
			}
        });
        yield put({
            type: bonusActions.BONUS_IS_LOADING,
            isLoading: false
        });
    } catch (error) {
        console.log(error);
    }
}

function* bonusSaga() {
    yield takeLatest(bonusActions.GET_BONUS, getBonuses);
}

export default bonusSaga;
