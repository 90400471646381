import reportMarginActions from "../actions/report-margin-actions";

const initialState = {
    isLoading: false,
    error: null,
    data: null,
};

const reportMarginReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportMarginActions.UPDATE_REPORT_MARGIN:
        case reportMarginActions.STORE_REPORT_MARGIN_PARAMS:
        case reportMarginActions.REPORT_MARGIN_IS_LOADING:
        case reportMarginActions.REPORT_MARGIN_LOAD_ERROR:
            return {
                ...state,
                ...action.data,
            };

        default:
            return state;
    }
};

export default reportMarginReducer;