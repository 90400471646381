import registerActions from '../actions/register-actions';

const initialState = {
    token: '',
    profile: {},
};

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case registerActions.UPDATE_REGISTER_CREDENTIALS:
        case registerActions.UPDATE_PROFILE_FOR_REGISTER:
            return {
                ...state,
                ...action.data,
            };

        case registerActions.CLEAR_REGISTER_CREDENTIALS:
            return {
                ...state,
                token: null,
            };

        default:
            return state;
    }
};

export default registerReducer;