import countryListActions from "../actions/country-list-actions";

const initialState = {};

const countryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case countryListActions.UPDATE_COUNTRY_LIST:
            const { countries } = action.data;
            const data = countries.reduce ( ( result, item, index ) => {
                result[index] = {
                    value: item.name,
                    label: item.name
                };
                return result;
            }, [] );
            return { data };
        default:
            return state;
    }
};

export default countryListReducer;