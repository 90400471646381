import config from "./config";
import axiosServices from "./axios";

const apiRequest = {
    general: {
        get: {
            getBanks: () => {
                const response = axiosServices.get(config.apiUrl.get.general.getBanks);
                return response;
            },
            getIndustries: () => {
                const response = axiosServices.get(config.apiUrl.get.general.getIndustries);
                return response;
            },
            getCountries: () => {
                const response = axiosServices.get(config.apiUrl.get.general.getCountries);
                return response;
            },
            getStates: (country) => {
                const response = axiosServices.get(config.apiUrl.get.general.getStates.replace("{country}", country));
                return response;
            },
        }
    },
    user: {
        post: {
            createQuotation: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.user.createQuotation, payload);
                return response;
            },
            subscribeEmail: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.user.subscribeEmail, payload);
                return response;
            },
            luckyDrawCampaign: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.user.luckyDrawCampaign, payload);
                return response;
            },
        },
        get: {
            luckyDrawCampaign: () => {
                const response = axiosServices.get(config.apiUrl.get.user.luckyDrawCampaign);
                return response;
            },
            luckyDrawWinners: () => {
                const response = axiosServices.get(config.apiUrl.get.user.luckyDrawWinners);
                return response;
            },
            insurersCount: (motorType) => {
                const response = axiosServices.get(config.apiUrl.get.user.insurersCount.replace("{motorType}", motorType));
                return response;
            },
            trackingOutbound: (suggestionId) => {
                const endPoint = config.apiUrl.get.user.trackingOutbound.replace("{suggestionId}", suggestionId);
                const response = axiosServices.get(endPoint);
                return response;
            },
            validateEnquiry: (params) => {
                const response = axiosServices.get(config.apiUrl.get.user.validateEnquiry, params);
                return response;
            },
        },
        put: {},
        delete: {},
    },
    admin: {
        post: {
            login: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.login, payload);
                return response;
            },
            logout: () => {
                const response = axiosServices.post(config.apiUrl.post.admin.logout);
                return response;
            },
            createUser: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.createUser, payload);
                return response;
            },
            editUser: (payload, insurerId) => {
                const endPoint = config.apiUrl.post.admin.editUser.replace("{userId}", insurerId);
                const response = axiosServices.post(endPoint, payload);
                return response;
            },
            resetPasswordSendEmail: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.resetPasswordSendEmail, payload);
                return response;
            },
            forceResetPassword: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.forceResetPassword, payload);
                return response;
            },
            activateAffiliate: (userId) => {
                const response = axiosServices.post(config.apiUrl.post.admin.activateAffiliate.replace('{userId}', userId));
                return response;
            },
            deactivateAffiliate: (userId) => {
                const response = axiosServices.post(config.apiUrl.post.admin.deactivateAffiliate.replace('{userId}', userId));
                return response;
            },
            inviteAffiliate: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.inviteAffiliate, payload);
                return response;
            },

            uploadDocument: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.uploadDocument, payload);
                return response;
            },
            updateAffiliateProfile: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.updateAffiliateProfile, payload);
                return response;
            },

            saveBanner: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.saveBanner, payload);
                return response;
            },
            saveLogo: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.saveLogo, payload);
                return response;
            },

            updateCommission: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.updateCommission, payload);
                return response; 
            },
            updateBonus: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.admin.updateBonus, payload);
                return response; 
            }
        },
        get: {
            profile: () => {
                const response = axiosServices.get(config.apiUrl.get.admin.profile);
                return response;
            },
            usersList: (params) => {
                const response = axiosServices.get(config.apiUrl.get.admin.usersList, params);
                return response;
            },
            insurerLists: (params) => {
                const response = axiosServices.get(config.apiUrl.get.admin.insurerLists, params);
                return response;
            },
            carMakeLists: (params) => {
                const response = axiosServices.get(config.apiUrl.get.admin.carMakeLists, params);
                return response;
            },
            modelLists: (params) => {
                const response = axiosServices.get(config.apiUrl.get.admin.modelLists, params);
                return response;
            },
            dateRanges: () => {
                const response = axiosServices.get(config.apiUrl.get.admin.dateRanges);
                return response;
            },
            affiliateList: () => {
                const response = axiosServices.get(config.apiUrl.get.admin.affiliateList);
                return response;
            },
            eventReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.eventReports.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            inquiryReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.inquiryReports.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            premiumReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.carPremiumReports.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            priceSectorReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.priceSectorReports.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            countryReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.getCountryTotalUser.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            deviceReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.getDeviceCategory.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            sourceReports: (params, user) => {
                const endPoint = config.apiUrl.get.admin.getSource.replace("{user}", user);
                const response = axiosServices.get(endPoint, params);
                return response;
            },
            viewDocument: (documentId) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.viewDocument.replace("{documentId}", documentId),
                    {},
                    { responseType: 'blob' }
                );
                return response; 
            },
            affiliateProfile: (affiliateId) => {
                const response = axiosServices.get(config.apiUrl.get.admin.affiliateProfile.replace('{affiliateId}', affiliateId));
                return response; 
            },
            getReportSummary: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.getReportSummary,
                    { startDate, endDate }
                );
                return response; 
            },
            getReportConversion: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.getReportConversion,
                    { startDate, endDate }
                );
                return response; 
            },
            exportReportConversion: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.exportReportConversion,
                    { startDate, endDate },
                    { responseType: 'blob' }
                );
                return response; 
            },
            getOneReportConversion: (reportId) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.getOneReportConversion.replace('{reportId}', reportId),
                );
                return response; 
            },
            getReportMargin: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.getReportMargin,
                    { startDate, endDate }
                );
                return response; 
            },
            exportReportMargin: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.admin.exportReportMargin,
                    { startDate, endDate },
                    { responseType: 'blob' }
                );
                return response; 
            },
            getCommissions: () => {
                const response = axiosServices.get(config.apiUrl.get.admin.getCommissions);
                return response; 
            },
            getBonuses: () => {
                const response = axiosServices.get(config.apiUrl.get.admin.getBonuses);
                return response; 
            }
        },
        delete: {
            user: (params) => {
                const endPoint = config.apiUrl.delete.admin.user.replace("{userId}", params.id);
                const response = axiosServices.delete(endPoint);
                return response;
            },
        },
        put: {},
    },
    affiliate: {
        post: {
            login: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.login, payload);
                return response;
            },
            logout: () => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.logout);
                return response;
            },
            
            resetPasswordSendEmail: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.resetPasswordSendEmail, payload);
                return response;
            },
            resetPassword: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.resetPassword, payload);
                return response;
            },
            
            register: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.register, payload);
                return response;
            },
            verifyEmail: (payload) => {
                const response = axiosServices.postRegister(config.apiUrl.post.affiliate.verifyEmail, payload);
                return response;
            },
            createPassword: (payload) => {
                const response = axiosServices.postRegister(config.apiUrl.post.affiliate.createPassword, payload);
                return response;
            },
            updateProfileRegister: (payload) => {
                const response = axiosServices.postRegister(config.apiUrl.post.affiliate.updateProfile, payload);
                return response;
            },

            uploadDocument: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.uploadDocument, payload);
                return response;
            },
            updateProfile: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.updateProfile, payload);
                return response;
            },

            saveBanner: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.saveBanner, payload);
                return response;
            },
            saveLogo: (payload) => {
                const response = axiosServices.post(config.apiUrl.post.affiliate.saveLogo, payload);
                return response;
            },
        },
        get: {
            getProfile: () => {
                const response = axiosServices.get(config.apiUrl.get.affiliate.getProfile);
                return response; 
            },
            getProfileRegister: () => {
                const response = axiosServices.getRegister(config.apiUrl.get.affiliate.getProfile);
                return response; 
            },
            getTheme: (code) => {
                const response = axiosServices.get(config.apiUrl.get.affiliate.getTheme, { code });
                return response;
            },
            viewDocument: (documentId) => {
                const response = axiosServices.get(
                    config.apiUrl.get.affiliate.viewDocument.replace("{documentId}", documentId),
                    {},
                    { responseType: 'blob' }
                );
                return response; 
            },
            getReportSummary: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.affiliate.getReportSummary,
                    { startDate, endDate }
                );
                return response; 
            },
            getReportConversion: (startDate, endDate) => {
                const response = axiosServices.get(
                    config.apiUrl.get.affiliate.getReportConversion,
                    { startDate, endDate }
                );
                return response; 
            }
        }
    }
};

export default apiRequest;
