import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import industryListActions from "../actions/industry-list-actions";

function* getIndustryList() {
    try {
        const response = yield call(apiRequest.general.get.getIndustries);
        yield put({ type: industryListActions.UPDATE_INDUSTRY_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* industryListSaga() {
    yield takeLatest(industryListActions.GET_INDUSTRY_LIST, getIndustryList);
}

export default industryListSaga;