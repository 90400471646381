import axios from "axios";
import { store } from "../../redux/store/store";

const axiosServices = {
    get: (url, params = {}, config = {}) => {
        return axios.get(url, { params: params, headers: getHeaders(), ...config });
    },
    getRegister: (url, params = {}, config = {}) => {
        return axios.get(url, { params: params, headers: getRegisterHeaders(), ...config });
    },
    post: (url, data = {}, config = {}) => {
        return axios.post(url, data, { headers: getHeaders(config) });
    },
    postRegister: (url, data = {}, config = {}) => {
        return axios.post(url, data, { headers: getRegisterHeaders(config) });
    },
    put: (url, data = {}) => {
        return axios.put(url, data, getHeaders());
    },
    patch: (url, data = {}) => {
        return axios.patch(url, data, getHeaders());
    },
    delete: (url, params = {}) => {
        return axios.delete(url, { params, headers: getHeaders() });
    },
};

const getHeaders = (config) => {
    const user = store.getState().user;
    let headers = {
        "Content-Type": "application/json",
        ...config,
    };
    if (user.token) {
        headers = Object.assign({}, headers, {
            Authorization: "Bearer " + user.token,
        });
    }
    return headers;
};

const getRegisterHeaders = (config) => {
    const register = store.getState().register;
    let headers = {
        "Content-Type": "application/json",
        ...config,
    };
    if (register.token) {
        headers = Object.assign({}, headers, {
            Authorization: "Bearer " + register.token,
        });
    }
    return headers;
};

export default axiosServices;
