import { call, put, takeLatest } from "redux-saga/effects";

import history from "../../common/helper/history";
import apiRequest from "../../common/services/api";
import userActions from "../actions/user-actions";

function* getUserProfile(action) {
    try {
        const response = yield call(apiRequest.admin.get.profile);
        yield put({ type: userActions.UPDATE_USER_PROFILE, data: response.data });
        yield call(history.push, "/admin/dashboard");
    } catch (error) {
        console.log(error);
    }
}

function* userSaga() {
    yield takeLatest(userActions.GET_USER_PROFILE, getUserProfile);
}

export default userSaga;
