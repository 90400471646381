import moment from "moment";
import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import reportSummaryActions from "../actions/report-summary-actions";

function* isLoading(isLoading) {
    yield put({
        type: reportSummaryActions.REPORT_SUMMARY_IS_LOADING, data: { isLoading }
    });
}

function* getReportSummary({ isAdmin, startDate, endDate }) {
    try {
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');

        yield call(isLoading, true);
        const response = yield call(apiRequest[isAdmin ? 'admin' : 'affiliate'].get.getReportSummary, startDate, endDate);
        yield put({ 
            type: reportSummaryActions.UPDATE_REPORT_SUMMARY,
            data: { data: response.data } 
        });
        yield call(isLoading, false);
    } catch ( error ) {
        console.log ( error );
        yield call(isLoading, false);
        yield put({
            type: reportSummaryActions.REPORT_SUMMARY_LOAD_ERROR, 
            data: { error }
        });
    }
}

function* reportSummarySaga() {
    yield takeLatest(reportSummaryActions.GET_REPORT_SUMMARY, getReportSummary);
}

export default reportSummarySaga;