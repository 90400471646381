import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import stateListActions from "../actions/state-list-actions";

function* getStateList(action) {
    try {
        const response = yield call(apiRequest.general.get.getStates, action.data);
        yield put({ type: stateListActions.UPDATE_STATE_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* stateListSaga() {
    yield takeLatest(stateListActions.GET_STATE_LIST, getStateList);
}

export default stateListSaga;