import React from "react";
import Routes from "./routes/routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, presistorStore } from "./redux/store/store";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={presistorStore}>
                <Routes />
            </PersistGate>
        </Provider>
    );
}

export default App;
