import carsModelListActions from "../actions/cars-model-list-action";

const initialState = {};

const carsModelListReducer = (state = initialState, action) => {
    switch (action.type) {
        case carsModelListActions.UPDATE_CARS_MODEL_LIST:
            return { ...action.data };
        case carsModelListActions.CLEAR_CARS_MODEL_LIST:
            return initialState;
        default:
            return state;
    }
};

export default carsModelListReducer;
