import alertActions from "../actions/alert-actions";

const initialState = {
    visible: false,
    message: "",
    error: true,
};

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case alertActions.SHOW_ALERT:
            return {
                ...state,
                visible: true,
                message: action.data.message,
                error: action.data.error ? true : false,
            };
        case alertActions.HIDE_ALERT:
            return initialState;
        default:
            return state;
    }
};

export default alertReducer;
