import { call, put, takeLatest } from "redux-saga/effects";
import apiRequest from "../../common/services/api";
import affiliateListActions from "../actions/affiliate-list-actions";

function* getAffiliateList() {
    try {
        let response = yield call(apiRequest.admin.get.affiliateList);
        const {
            affiliates
        } = response.data;
        yield put({ 
            type: affiliateListActions.UPDATE_AFFILIATE_LIST, 
            data: {
				affiliates
			}
        });
    } catch (error) {
        console.log(error);
    }
}

function* affiliateListSaga() {
    yield takeLatest(affiliateListActions.GET_AFFILIATE_LIST, getAffiliateList);
}

export default affiliateListSaga;
