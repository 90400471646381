import documentActions from "../actions/document-actions";

const initialState = {
    documents: {},
};

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
	case documentActions.UPDATE_DOCUMENT:
		let documents = { ...state.documents };
		const {id, url} = action.data;
		documents[id] = url;
		return {
			...state,
			documents
		};

	default:
		return state;
    }
};

export default documentReducer;