const base = {
	host: process.env.REACT_APP_API_ENDPOINT,
};

const config = {
	websocket: {
		url: base.host + "b2c-api-websocket",
		subscribe: "/api/topic/quotation-suggestions/",
		requestQuotation: "/api/app/request-quotation/",
	},
	apiUrl: {
		get: {
			general: {
				getBanks: base.host + "v1/general/data/banks",
				getIndustries: base.host + "v1/general/data/industries",
				getCountries: base.host + "v1/general/data/countries",
				getStates: base.host + "v1/general/data/states?country={country}",
			},
			user: {
				luckyDrawCampaign: base.host + "v1/general/campaign/checkCampaign",
				validateEnquiry: base.host + "v1/general/quotation/validateEnquiry",
				insurersCount: base.host + "v1/general/quotation/insurer-count?motorType={motorType}",
				trackingOutbound: base.host + "v1/general/quotation/selection?quotationSuggestionId={suggestionId}",
				luckyDrawWinners: base.host + "v1/general/campaign/drawingWinners",
			},
			admin: {
				profile: base.host + "v1/general/account/status",
				usersList: base.host + "v1/admin/insureruser/search",
				insurerLists: base.host + "v1/admin/insurer/search",
				carMakeLists: base.host + "v1/general/report/search-make",
				modelLists: base.host + "v1/general/report/search-model",
				dateRanges: base.host + "v1/general/report/list-date-ranges",
				eventReports: base.host + "v1/{user}/report/ga4Events",
				inquiryReports: base.host + "v1/{user}/report/inquiries",
				carPremiumReports: base.host + "v1/{user}/report/average-premium",
				priceSectorReports: base.host + "v1/{user}/report/price-sector",
				affiliateList: base.host + "v1/admin/affiliate/search",
				viewDocument: base.host + "v1/admin/data/files/{documentId}",
				affiliateProfile: base.host + "v1/admin/affiliate/{affiliateId}",
				getReportSummary: base.host + "v1/admin/affiliate/report/summary/by-date-range",
				getReportConversion: base.host + "v1/admin/affiliate/report/conversion/by-date-range",
				exportReportConversion: base.host + "v1/admin/affiliate/report/conversion/export/by-date-range",
				getOneReportConversion: base.host + "v1/admin/affiliate/report/conversion/{reportId}",
				getReportMargin: base.host + "v1/admin/affiliate/report/margin/by-date-range",
				exportReportMargin: base.host + "v1/admin/affiliate/report/margin/export/by-date-range",
				getCommissions: base.host + "v1/admin/affiliate/commissions",
				getBonuses: base.host + "v1/admin/affiliate/bonuses",
				getCountryTotalUser: base.host + "v1/{user}/report/ga4UsersCountry",
				getDeviceCategory: base.host + "v1/{user}/report/ga4DeviceCategory",
				getSource: base.host + "v1/{user}/report/ga4Source",
			},
			affiliate: {
				getProfile: base.host + "v1/affiliate/account/status",
				viewDocument: base.host + "v1/affiliate/data/files/{documentId}",
				getTheme: base.host + "v1/affiliate/data/theme",
				getReportSummary: base.host + "v1/affiliate/report/summary/by-date-range",
				getReportConversion: base.host + "v1/affiliate/report/conversion/by-date-range",
			},
		},
		post: {
			user: {
				createQuotation: base.host + "v1/general/quotation/create",
				subscribeEmail: base.host + "v1/general/email-subscription/create",
				luckyDrawCampaign: base.host + "v1/general/campaign/luckyDrawEntry",
			},
			admin: {
				login: base.host + "v1/general/account/login",
				logout: base.host + "v1/general/account/logout",
				createUser: base.host + "v1/admin/insureruser/create",
				editUser: base.host + "v1/admin/insureruser/{userId}/edit",
				resetPasswordSendEmail: base.host + "v1/general/account/send-password-reset-mail",
				forceResetPassword: base.host + "v1/general/account/reset-password",
				activateAffiliate: base.host + "v1/admin/affiliate/{userId}/activate",
				deactivateAffiliate: base.host + "v1/admin/affiliate/{userId}/deactivate",
				inviteAffiliate: base.host + "v1/admin/affiliate/invite",
				uploadDocument: base.host + "v1/admin/data/upload",
				updateAffiliateProfile: base.host + "v1/admin/affiliate/update-profile",

				saveBanner: base.host + "v1/admin/affiliate/update-banner",
				saveLogo: base.host + "v1/admin/affiliate/update-logo",

				updateCommission: base.host + "v1/admin/affiliate/update-commission",
				updateBonus: base.host + "v1/admin/affiliate/update-bonus",
			},
			affiliate: {
				login: base.host + "v1/affiliate/account/login",
				logout: base.host + "v1/affiliate/account/logout",

				resetPasswordSendEmail: base.host + "v1/affiliate/account/send-password-reset-mail",
				resetPassword: base.host + "v1/affiliate/account/reset-password",

				register: base.host + "v1/affiliate/account/register",
				verifyEmail: base.host + "v1/affiliate/account/verify-email",
				createPassword: base.host + "v1/affiliate/account/set-first-password",

				uploadDocument: base.host + "v1/affiliate/data/upload",
				updateProfile: base.host + "v1/affiliate/account/update-profile",
				saveBanner: base.host + "v1/affiliate/account/update-banner",
				saveLogo: base.host + "v1/affiliate/account/update-logo",
			},
		},
		delete: {
			user: {},
			admin: {
				user: base.host + "v1/admin/insureruser/{userId}/delete",
			},
		},
	},
	features: [
		{
			label: "Site traffic history",
			value: "siteTrafficHistoryPermission",
		},
		{
			label: "Data analytics",
			value: "dataAnalyticsPermission",
		},
	],
	affiliateCode: {
		PPIM: "A000016",
		PPM: "A000015",
		MDG: "A000019",
	},
	motorType: {
		motorcar: "MOTORCAR",
		motorcycle: "MOTORCYCLE",
	},
};

export default config;
