import React, { Fragment, useState } from "react";
import { FormGroup, Label, Input, Button, ButtonGroup } from "reactstrap";
import classNames from "classnames";

import config from "../../../src/common/services/config";
import greenCarIcon from "../../assets/images/svg/green-car-icon.svg";
import greenBikeIcon from "../../assets/images/svg/green-motorcycle-icon.svg";
import whiteCarIcon from "../../assets/images/svg/white-car-icon.svg";
import whiteBikeIcon from "../../assets/images/svg/white-motorcycle-icon.svg";

const TabInputBox = (props) => {
	const [activeTab, setActiveTab] = useState(props.defaultValue);
	const [inputValue, setInputValue] = useState("");
	const motorType = config.motorType;
	const [carHovered, setCarHovered] = useState(false);
	const [bikeHovered, setBikeHovered] = useState(false);

	const tabInputWrapperClasses = classNames({
		"c-tabs-input__input-wrapper": true,
		"c-tabs-input__input-wrapper--disabled": props.disabled,
		"c-tabs-input__input-wrapper--invalid": props.invalid,
	});

	const tabInputSectionClasses = classNames({
		"c-tabs-input": true,
		"c-tabs-input--invalid": props.invalid,
		"c-tabs-input--upperCase": props.uppercase,
	});

	const isActiveTab = (value) => {
		return activeTab === value ? "c-tabs-input__tab c-tabs-input__tab--active" : "c-tabs-input__tab";
	};

	const onChangeActiveTab = (value) => {
		setActiveTab(value);
		props.onChangeTabInput(value);
		setInputValue("");
	};

	const TabItems = () => {
		if (!props.tabItemsData || props.tabItemsData.length < 1) {
			return null;
		}

		if (!!props.icon) {
			return (
				<Fragment>
					<Button className={isActiveTab(motorType.motorcar)} onClick={() => onChangeActiveTab(motorType.motorcar)} onMouseEnter={() => setCarHovered(true)} onMouseLeave={() => setCarHovered(false)}>
						<img src={activeTab === motorType.motorcar || carHovered ? whiteCarIcon : greenCarIcon} width={30} alt="Car icon" />
					</Button>
					<Button className={isActiveTab(motorType.motorcycle)} onClick={() => onChangeActiveTab(motorType.motorcycle)} onMouseEnter={() => setBikeHovered(true)} onMouseLeave={() => setBikeHovered(false)}>
						<img src={activeTab === motorType.motorcycle || bikeHovered ? whiteBikeIcon : greenBikeIcon} width={30} alt="Motorcycle icon" />
					</Button>
				</Fragment>
			);
		} else {
			return props.tabItemsData.map((item, index) => {
				return (
					<Button disabled={props.disabled} key={index} className={isActiveTab(item.value)} onClick={() => onChangeActiveTab(item.value)}>
						{item.label}
					</Button>
				);
			});
		}
	};

	const ErrorMessage = () => {
		const errorMessage = props.invalid && props.errorMessage ? props.errorMessage : "";
		return <p className="c-tabs-input__error-msg">{errorMessage}</p>;
	};

	const onHandleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	return (
		<Fragment>
			<FormGroup className={tabInputSectionClasses}>
				<Label className="c-tabs-input__label" for={props.label.toLowerCase()}>
					{props.label}
				</Label>
				<ButtonGroup className="c-tabs-input__tabs">
					<TabItems />
				</ButtonGroup>
				<div className={tabInputWrapperClasses}>
					<Input id="idTypeInput" hidden readOnly type="text" name={`${props.name}Type`} value={activeTab} />
					<Input
						id={props.label.toLowerCase()}
						disabled={props.disabled}
						className="c-tabs-input__input"
						type={props.type}
						name={props.name}
						placeholder={props.placeholder}
						onKeyUp={props.onKeyUp}
						onBlur={props.onBlur}
						value={inputValue}
						onChange={onHandleInputChange}
					/>
				</div>
			</FormGroup>
			<ErrorMessage />
		</Fragment>
	);
};

TabInputBox.defaultProps = {
	label: "label",
	type: "text",
	name: "input",
	placeholder: "",
	tabItemsData: [],
	defaultValue: "",
	onChange: () => {},
	onBlur: () => {},
	onChangeTabInput: () => {},
};

export default TabInputBox;
