import { call, put, takeLatest } from "redux-saga/effects";

import history from "../../common/helper/history";
import apiRequest from "../../common/services/api";
import registerActions from "../actions/register-actions";

function* getProfileForRegister(action) {
    try {
        const response = yield call(apiRequest.affiliate.get.getProfileRegister);

        const { affiliateProfile } = response.data;
        
        yield put({ 
            type: registerActions.UPDATE_PROFILE_FOR_REGISTER, 
            data: {
                profile: affiliateProfile,
            },
        });

        history.replace('/affiliate/register/complete');
    } catch (error) {
        console.log(error);
    }
}

function* registerSaga() {
    yield takeLatest(registerActions.GET_PROFILE_FOR_REGISTER, getProfileForRegister);
}

export default registerSaga;