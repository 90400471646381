import { call, put, takeLatest } from "redux-saga/effects";

import history from "../../common/helper/history";
import apiRequest from "../../common/services/api";
import affiliateActions from "../actions/affiliate-actions";

function* getProfile(action) {
    try {
        let response;
        if (action.isAdmin) {
            response = yield call(apiRequest.admin.get.affiliateProfile, action.affiliateId);
        }
        else {
            response = yield call(apiRequest.affiliate.get.getProfile);
        }

        const {
            affiliateProfile
        } = response.data;

        yield put({ 
            type: affiliateActions.UPDATE_AFFILIATE_PROFILE, 
            data: {
                profile: affiliateProfile
            } 
        });

        // Get photo docs
        const {
            profilePhotoId,
            logoPhotoId,
            bannerPhotoId
        } = affiliateProfile;
        if (profilePhotoId) {
            yield put ({ type: affiliateActions.GET_AFFILIATE_PROFILE_PIC, data: profilePhotoId });
        }
        if (logoPhotoId) {
            yield put ({ type: affiliateActions.GET_AFFILIATE_LOGO_PIC, data: logoPhotoId });
        }
        if (bannerPhotoId) {
            yield put ({ type: affiliateActions.GET_AFFILIATE_BANNER_PIC, data: bannerPhotoId });
        }

        if ( !action.dontRedirect ) {
            yield call(history.push, "/affiliate/dashboard");
        }
    } catch (error) {
        console.log(error);
    }
}

function* getProfilePic(action) {
    try {
        yield put({
            type: affiliateActions.UPDATE_AFFILIATE_PROFILE_PIC,
            data: { profilePhotoURL: null }
        });

        const response = yield call(apiRequest.affiliate.get.viewDocument, action.data);
        const profilePhotoURL = URL.createObjectURL ( response.data );
        yield put({
            type: affiliateActions.UPDATE_AFFILIATE_PROFILE_PIC,
            data: { profilePhotoURL }
        });
    } catch (error) {
        console.log(error);
    }
}

function* getLogoPic(action) {
    try {
        const response = yield call(apiRequest.affiliate.get.viewDocument, action.data);
        const logoPhotoURL = URL.createObjectURL ( response.data );

        yield put({
            type: affiliateActions.UPDATE_AFFILIATE_LOGO_PIC,
            data: { logoPhotoURL }
        });
    } catch (error) {
        console.log(error);
    }
}

function* getBannerPic(action) {
    try {
        const response = yield call(apiRequest.affiliate.get.viewDocument, action.data);
        const bannerPhotoURL = URL.createObjectURL ( response.data );

        yield put({
            type: affiliateActions.UPDATE_AFFILIATE_BANNER_PIC,
            data: { bannerPhotoURL }
        });
    } catch (error) {
        console.log(error);
    }
}

function* affiliateSaga() {
    yield takeLatest(affiliateActions.GET_AFFILIATE_PROFILE, getProfile);
    yield takeLatest(affiliateActions.GET_AFFILIATE_PROFILE_PIC, getProfilePic);
    yield takeLatest(affiliateActions.GET_AFFILIATE_LOGO_PIC, getLogoPic);
    yield takeLatest(affiliateActions.GET_AFFILIATE_BANNER_PIC, getBannerPic);
}

export default affiliateSaga;