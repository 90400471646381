import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import bankListActions from "../actions/bank-list-actions";

function* getBankList() {
    try {
        const response = yield call(apiRequest.general.get.getBanks);
        yield put({ type: bankListActions.UPDATE_BANK_LIST, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* bankListSaga() {
    yield takeLatest(bankListActions.GET_BANK_LIST, getBankList);
}

export default bankListSaga;