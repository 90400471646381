import React from 'react';
import CarilarToS from '../../../assets/pdfs/Carilar_ToS.pdf';
import CarilarPrivacyPolicy from '../../../assets/pdfs/Carilar_PrivacyPolicy.pdf';

const customStyles = {
    width: '100vw',
    height: '100vh',
};

export const ToS = () => (
    <div style={customStyles}>
        <object data={CarilarToS} type="application/pdf" width="100%" height="100%">
        </object>
    </div>
);

export const PrivacyPolicy = () => (
    <div style={customStyles}>
        <object data={CarilarPrivacyPolicy} type="application/pdf" width="100%" height="100%">
        </object>
    </div>
);