import moment from "moment";
import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import reportMarginActions from "../actions/report-margin-actions";

function* isLoading(isLoading) {
    yield put({
        type: reportMarginActions.REPORT_MARGIN_IS_LOADING, data: { isLoading }
    });
}

function* getReportMargin({ isAdmin, startDate, endDate }) {
    try {
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');

        yield call(isLoading, true);
        const response = yield call(apiRequest[isAdmin ? 'admin' : 'affiliate'].get.getReportMargin, startDate, endDate);
        yield put({ 
            type: reportMarginActions.UPDATE_REPORT_MARGIN,
            data: { data: response.data } 
        });
        yield put({ 
            type: reportMarginActions.STORE_REPORT_MARGIN_PARAMS, 
            data: { startDate, endDate } 
        });
        yield call(isLoading, false);
    } catch ( error ) {
        console.log ( error );
        yield call(isLoading, false);
        yield put({
            type: reportMarginActions.REPORT_MARGIN_LOAD_ERROR, 
            data: { error }
        });
    }
}

function* reportMarginSaga() {
    yield takeLatest(reportMarginActions.GET_REPORT_MARGIN, getReportMargin);
}

export default reportMarginSaga;