import bonusActions from '../actions/bonus-actions';

const initialState = {
    bonuses: [],
	isLoading: false,
};

const bonusReducer = (state = initialState, action) => {
    switch (action.type) {
	case bonusActions.UPDATE_BONUS:
		return {
			...state,
			...action.data,
		};

	case bonusActions.BONUS_IS_LOADING:
		return {
			...state,
			isLoading: action.isLoading,
		};

	default:
		return state;
    }
};

export default bonusReducer;