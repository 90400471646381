import usersListActions from "../actions/users-list-actions";

const initialState = {};

const usersListReducer = (state = initialState, action) => {
    switch (action.type) {
        case usersListActions.UPDATE_USERS_LIST:
            return { ...action.data };
        default:
            return state;
    }
};

export default usersListReducer;
