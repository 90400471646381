import React, { memo } from "react";
import { Button } from "reactstrap";
import classNames from "classnames";

const PrimaryButton = ({ outline, disabled, onClick, icon, label, wAuto, clear, extraClasses, noMargin, clearSize }) => {
    const buttonClasses = classNames({
        "btn-wrapper__outline": outline && !clear,
        "btn-wrapper__clear": !outline && clear,
        "btn-wrapper__btn": !outline && !clear,
        "m-0": noMargin,
    });
    return (
        <div 
            className={`btn-wrapper
            ${wAuto ? 'w-auto' : ''}
            ${extraClasses}
            `}
        >
            <Button 
                className={buttonClasses} 
                disabled={disabled} 
                onClick={onClick}
                style={clearSize ? {
                    minWidth: 'unset',
                    height: 'auto'
                }: {}}
            >
                {icon} {label}
            </Button>
        </div>
    );
};

export default memo(PrimaryButton);

PrimaryButton.defaultProps = {
    label: "Continue",
    type: "submit",
    icon: null,
    wAuto: false
};