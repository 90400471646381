import affiliateListActions from "../actions/affiliate-list-actions";

const initialState = {
    affiliates: [],
	isLoading: false,
};

const affiliateListReducer = (state = initialState, action) => {
    switch (action.type) {
	case affiliateListActions.UPDATE_AFFILIATE_LIST:
		return {
			...state,
			...action.data,
		};

	case affiliateListActions.AFFILIATE_LIST_IS_LOADING:
		return {
			...state,
			isLoading: action.isLoading,
		};

	default:
		return state;
    }
};

export default affiliateListReducer;