import insurersActions from "../actions/insurer-actions";

const initialState = {};

const insurersReducer = (state = initialState, action) => {
    switch (action.type) {
        case insurersActions.UPDATE_INSURER_LISTS:
            return { ...action.data };
        default:
            return state;
    }
};

export default insurersReducer;
