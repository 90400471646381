import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Input } from "reactstrap";
import classNames from "classnames";

import B2C_tick_icon from "../../assets/images/tick.png";

const CheckBoxInputBox = forwardRef((props, ref) => {
    const checkBoxClasses = classNames({
        "c-checkbox-input__checkbox": true,
        "c-checkbox-input__checkbox--invalid": props.invalid,
    });
    const inputRef = useRef(null);
    const [checkBoxActive, setCheckBoxActive] = useState(props.value === true ? 1 : 0);

    const onChangeTick = () => {
        if (props.disabled) return;

        const isActive = !checkBoxActive ? 1 : 0;
        setCheckBoxActive(isActive);

        props.onChange(isActive);
    };

    const isActive = () => {
        return checkBoxActive ? "c-checkbox-input__tick c-checkbox-input__tick--active" : "c-checkbox-input__tick";
    };

    useImperativeHandle(ref, () => {
        return {
            onChangeTick: onChangeTick,
        };
    });

    return (
        <div className="c-checkbox-input">
            <Input
                hidden
                readOnly
                type="text"
                name={props.name}
                value={checkBoxActive}
                ref={inputRef}
                onChange={props.onChange}
            />
            <div className={checkBoxClasses} onClick={onChangeTick}>
                <div className={isActive()}>
                    <img alt="accept terms and conditions" src={B2C_tick_icon} />
                </div>
            </div>
        </div>
    );
});

CheckBoxInputBox.defaultProps = {
    name: "input",
    value: false,
    onChange: () => {},
};

export default CheckBoxInputBox;
