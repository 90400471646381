import React, { memo, Fragment, useState, useCallback, useRef, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import classNames from "classnames";

import openEye from "../../assets/images/eye.svg";
import closeEye from "../../assets/images/eye-off-outline.svg";
import uploadIcon from "../../assets/images/upload-icon.svg";
import DropdownInputBox from "components/dropdown-input/dropdown-input";
import { PrimaryButton } from "components";
import { useDropzone } from "react-dropzone";

const InputBox = (props) => {
	const typeIsPassword = props.type === "password";
	const [passwordProps, setPasswordProps] = useState({ openEyes: false, type: props.type });
	const inputType = typeIsPassword ? passwordProps.type : props.type;
	const eyesImage = !passwordProps.openEyes ? openEye : closeEye;
	const inputSectionClasses = classNames({
		"c-input": true,
		"c-input--disabled": props.disabled,
		"c-input--invalid": props.invalid,
	});
	const inputClasses = classNames({
		"c-input__input": !typeIsPassword,
		"c-input__input-password": typeIsPassword,
		"c-input__input--upperCase": props.textTransform === "uppercase",
	});

	const toggleEyes = useCallback(() => {
		setPasswordProps({
			...passwordProps,
			openEyes: !passwordProps.openEyes,
			type: !passwordProps.openEyes ? "text" : "password",
		});
	}, [passwordProps]);

	return (
		<Fragment>
			{props.label && props.labelOut && (
				<Label className="c-input__label mb-2" for={(props.label ?? props.name).toLowerCase()}>
					{props.label}
				</Label>
			)}
			<FormGroup className={inputSectionClasses}>
				<Label className="c-input__label" for={(props.label ?? props.name).toLowerCase()}>
					{!props.labelOut && props.label}
					<span>{props.optional && "(optional)"}</span>
					{props.readOnlyDisplayText ? (
						<p className="m-0 text-dark pt-1 pb-1" style={{ fontWeight: 400, fontSize: 16 }}>
							{props.defaultValue}
						</p>
					) : (
						<Input
							id={(props.label ?? props.name).toLowerCase()}
							ref={props.ref}
							className={inputClasses}
							type={inputType}
							name={props.name}
							defaultValue={props.defaultValue}
							placeholder={props.placeholder}
							onKeyUp={props.onKeyUp}
							onBlur={props.onBlur}
							disabled={props.disabled}
							readOnly={props.readOnly}
							rows={props.type === "textarea" ? 4 : undefined}
							value={props.value}
        					onChange={props.onInputChange}
						/>
					)}
				</Label>
				{props.icon ?? <Eyes type={props.type} toggle={toggleEyes} icon={eyesImage} />}
			</FormGroup>
			{!props.hideError && <ErrorMessage errorMessage={props.errorMessage} />}
			{props.hint && <p className="mt-2">{props.hint}</p>}
		</Fragment>
	);
};

const memoErrorMessage = ({ errorMessage }) => {
	return <p className="c-input__error-msg">{errorMessage}</p>;
};

const memoEyeIcon = ({ type, icon, toggle }) => {
	if (type === "password") {
		return <img className="c-input__eyes" src={icon} alt="password-eye" onClick={toggle} />;
	}
	return null;
};

const ErrorMessage = memo(memoErrorMessage);
const Eyes = memo(memoEyeIcon);

InputBox.defaultProps = {
	label: null,
	type: "text",
	name: "input",
	placeholder: "",
	onBlur: () => {},
	readOnly: false,
};

export default InputBox;

const handleFileUpload = (
	data,
	maxSizeMB = 1, // MB
	readAsBinary = false
) => {
	return new Promise((resolve, reject) => {
		if (!data || data.length <= 0) {
			reject("No file selected");
		}

		let uploadedFiles = [];
		for (let a = 0; a < data.length; ++a) {
			let file = data[a];
			if (maxSizeMB && file.size > maxSizeMB * 1000000) {
				reject(`'${file.name}' is too large, file must be ${maxSizeMB} Mb or below.`);
			}

			let reader = new FileReader();
			if (readAsBinary) {
				reader.readAsBinaryString(file);
			} else {
				reader.readAsDataURL(file);
			}
			reader.onerror = (err) => {
				reject(err);
			};
			reader.onloadend = (e) => {
				if (readAsBinary) {
					uploadedFiles.push(e.target.result);
				} else {
					uploadedFiles.push(file);
				}
				if (uploadedFiles.length > 0 && uploadedFiles.length >= data.length) {
					resolve(uploadedFiles);
				}
			};
		}
	});
};

export const EnhancedInputBox = (props) => {
	const hiddenInputRef = useRef();
	const [inputValue, setInputValue] = useState(props.defaultValue);
	const [fileValue, setFileValue] = useState(props.imageFileValue);
	// For file use
	const multiple = false;

	useEffect(() => {
		setFileValue(props.imageFileValue);
	}, [props.imageFileValue]);

	const onSelectInput = () => {
		hiddenInputRef.current.click();
	};

	const onFileUpload = async ({ target: { files } }) => {
		try {
			let uploadedFiles = await handleFileUpload(files);
			let event = {
				target: {
					name: props.name,
					value: uploadedFiles.length > 1 ? uploadedFiles : uploadedFiles[0],
				},
			};

			if (props.onChange) {
				props.onChange(event);
			}
			if (props.onBlur) {
				props.onBlur(event);
			}

			let val = "";
			uploadedFiles.forEach((file) => {
				if (val.length > 0) {
					val += ", ";
				}
				val += file.name;
				setFileValue(URL.createObjectURL(file));
			});
			setInputValue(val);
		} catch (err) {
			console.log(err);
		}
	};

	let inputComponent;
	switch (props.type) {
		default:
			inputComponent = (
				<div className="c-input__field">
					<InputBox {...props} label={null} defaultValue={inputValue} />
				</div>
			);
			break;

		case "file":
			inputComponent = (
				<div className="c-input__field c-input__enhanced" onClick={onSelectInput}>
					<input type={props.type} ref={hiddenInputRef} className="hidden" accept={props.type === "file" ? ".png, .jpeg, .jpg" : undefined} onChange={onFileUpload} multiple={props.type === "file" ? multiple : undefined} />

					{fileValue && <img className="c-input__enhanced-image mb-3" src={fileValue} alt="file-img" />}

					<InputBox
						{...props}
						label={props.horizontal ? null : props.label}
						defaultValue={inputValue}
						type="text"
						readOnly
						icon={<img className="c-input__enhanced-icon" src={uploadIcon} alt="uploadBtn" />}
						onChange={undefined}
						onBlur={undefined}
						hideError={true}
					/>
				</div>
			);
			break;
	}

	if (props.horizontal) {
		return (
			<div className="c-input-horizontal">
				<p className="label">
					<span>{props.label}</span>
					{props.required && <span className="text-danger">*</span>}
				</p>
				<span className="separator">:</span>
				{inputComponent}
			</div>
		);
	} else {
		return inputComponent;
	}
};

EnhancedInputBox.defaultProps = {
	label: null,
	type: "text",
	name: "input",
	placeholder: "",
	labelOut: false,
	horizontal: false,
	onBlur: () => {},
};

export const EnhancedInputBoxWithDropdown = (props) => {
	return (
		<div className="c-input-horizontal">
			<p className="label">
				<span>{props.label}</span>
				{props.required && <span className="text-danger">*</span>}
			</p>
			<span className="separator">:</span>
			<div className="c-input__multiple">
				<div className="input-item">
					<DropdownInputBox label={null} name={props.dropdownName} placeholder={props.dropdownPlaceholder} data={props.dropdownData} onSelected={props.onSelected} defaultValue={props.dropdownDefaultValue} double />
				</div>
				<div className="input-item">
					<InputBox {...props} label={null} />
				</div>
			</div>
		</div>
	);
};

EnhancedInputBoxWithDropdown.defaultProps = {
	label: null,
	type: "text",
	name: "input",
	placeholder: "",
	required: false,
	onBlur: () => {},
	dropdownName: "dropdown",
	dropdownPlaceholder: "Select",
	dropdownData: [],
	dropdownDefaultValue: null,
	onSelected: () => {},
};

const MB_ZEROES = 1000000;

export const ImageUploadBox = (props) => {
	const [defaultValue, setDefaultValue] = useState(null);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (props.defaultValue != null) {
			setDefaultValue(props.defaultValue);
		}

		if (props.error != null) {
			setError(null);
		}
	}, [props.defaultValue, props.error]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			let nextUploadedFiles = [];
			acceptedFiles.forEach((file) => {
				if (file.size >= props.maxSizeMB * MB_ZEROES) {
					setError(`File size exceeded the limit: ${props.maxSizeMB}MB`);
				} else {
					const reader = new FileReader();

					reader.onabort = () => setError("File reading was aborted");
					reader.onerror = () => setError("Error reading file");
					reader.onload = () => {
						// const binaryStr = reader.result;
						// console.log ( binaryStr );
						nextUploadedFiles.push(file);

						if (nextUploadedFiles.length >= acceptedFiles.length) {
							setUploadedFiles(nextUploadedFiles);
							setError(null);
							if (props.onChange) {
								props.onChange(nextUploadedFiles);
							}
						}
					};
					reader.readAsArrayBuffer(file);
				}
			});
		},
		[props]
	);

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: props.maxFiles,
		onDrop,
	});

	return (
		<div
			{...getRootProps({
				className: `c-input-upload-box
            ${error != null || props.error != null ? "error" : ""}
            `,
			})}
		>
			<input {...getInputProps()} multiple={false} />
			{uploadedFiles && uploadedFiles.length > 0 ? <img src={URL.createObjectURL(uploadedFiles[0])} alt="upload-img" /> : defaultValue != null ? <img src={defaultValue} alt="upload-img" /> : <span></span>}
			<h6
				className={`c-input-upload-box__title
                ${uploadedFiles != null && uploadedFiles.length > 0 ? "active" : ""}
                `}
			>
				{uploadedFiles == null || uploadedFiles.length <= 0
					? props.instructions
					: uploadedFiles.map((file, index) => (
							<span key={index}>
								{index > 0 && ", "}
								{file.name}
							</span>
					  ))}
			</h6>
			<PrimaryButton type="button" outline label="Browse" readOnly onClick={() => {}} />
			{(error || props.error) && <p className="c-input-upload-box__error-msg">{error ?? props.error}</p>}
		</div>
	);
};

ImageUploadBox.defaultProps = {
	maxFiles: 1,
	maxSizeMB: 1,
	instructions: "Drag image here or",
	onChange: () => {},
	error: null,
};
