import React, { memo } from "react";
import { Alert } from "reactstrap";
import { IoIosClose, IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";

import alertActions from "../../redux/actions/alert-actions";

const AlertPopup = props => {
    const alertState = useSelector(state => state.alert);
    const dispatch = useDispatch();

    const closeAlert = () => {
        dispatch({ type: alertActions.HIDE_ALERT });
    };

    if (!alertState.visible) return null;
    return (
        <Alert className="c-alert" color={alertState.error ? "danger" : "success"}>
            <div className="c-alert__wrapper">
                {alertState.error ? <IoIosCloseCircle /> : <IoIosCheckmarkCircle />}
                <p className="c-alert__text">
                    {alertState.message}
                </p>
            </div>
            <IoIosClose className="c-alert__close-btn" size={30} onClick={closeAlert} />
        </Alert>
    );
};

export default memo(AlertPopup);
