import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { IoIosMenu, IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import apiRequest from "../../common/services/api";
import userActions from "../../redux/actions/user-actions";
import alertActions from "../../redux/actions/alert-actions";

import DashboardIcon from "../../assets/images/stats-chart-outline.svg";
import SettingsIcon from "../../assets/images/settings-outline.svg";
import PeopleIcon from "../../assets/images/people-outline-icon.svg";
import ReportIcon from "../../assets/images/report-outline-icon.svg";
import { SwitchLanguage } from "components";
import UserProfile from "components/user-profile/user-profile";

const Menu = (props) => {
	const { t } = useTranslation(["translation"]);
	const [toggleMenu, setToggleMenu] = useState(false);
	const dispatch = useDispatch();
	const globalState = useSelector((state) => state);
	const adminRole = globalState.user.profile.roleName === "Admin";
	const basePath = adminRole ? "/admin/" : "/affiliate/";

	const logout = useCallback(
		async (event) => {
			event.preventDefault();

			try {
				await apiRequest.admin.post.logout();

				dispatch({ type: userActions.USER_LOGOUT });

				if (adminRole) {
					props.history.replace("/admin");
				} else {
					props.history.replace("/affiliate");
				}
			} catch (error) {
				let errorMessage = t("admin.logout.failed");

				dispatch({ type: alertActions.SHOW_ALERT, data: { message: errorMessage, error: true } });
			}
		},
		[dispatch, props.history, t, adminRole]
	);

	const toggleHamburger = () => {
		const mbMenuLists = document.querySelector(".c-menu-mb__lists");
        
		setToggleMenu(!toggleMenu);

		if (!toggleMenu) {
			mbMenuLists.classList.add("c-menu-mb__lists--active");
		} else {
			mbMenuLists.classList.remove("c-menu-mb__lists--active");
		}
	};

	useEffect(() => {
		const menuItems = document.querySelectorAll(".c-menu__text");

		menuItems.forEach((item) => {
			if (item.innerText.toLowerCase() === props.active.toLowerCase()) {
				item.closest(".c-menu__item").classList.add("c-menu__item--active");
			}
		});
	}, [props.active]);

	return (
		<Fragment>
			<div className="d-flex justify-content-between align-items-center w-100">
				<ul className="c-menu w-auto">
					<li className="c-menu__item">
						<Link to={`${basePath}dashboard`}>
							<img src={DashboardIcon} alt="dashboard" />
							<p className="c-menu__text">Dashboard</p>
						</Link>
					</li>
					{adminRole && (
						<Fragment>
							<li className="c-menu__item">
								<Link to={`${basePath}affiliates`}>
									<img src={PeopleIcon} alt="people" />
									<p className="c-menu__text">Affiliate List</p>
								</Link>
							</li>
							<li className="c-menu__item">
								<Link to={`${basePath}reports`}>
									<img src={ReportIcon} alt="report" />
									<p className="c-menu__text">Reporting</p>
								</Link>
							</li>
						</Fragment>
					)}
					<li className="c-menu__item">
						<Link to={`${basePath}settings`}>
							<img src={SettingsIcon} alt="settings" />
							<p className="c-menu__text">Settings</p>
						</Link>
					</li>
				</ul>

				<div className="d-flex align-items-center">
					{!adminRole && <UserProfile light profilePhotoURL={globalState.affiliate.profilePhotoURL} username={globalState.affiliate.profile.name} />}
					<button className="c-menu__item c-menu__item-padding ml-3 mr-3" onClick={logout}>
						<span className="c-menu__text">Logout</span>
					</button>
					{!adminRole && <SwitchLanguage />}
				</div>
			</div>

			<div className="c-menu-mb">
				<IoIosMenu size={30} color="#ffffff" onClick={toggleHamburger} />
				<ul className="c-menu-mb__lists">
					<IoIosClose className="c-menu-mb__close-btn" size={40} color="#ffffff" onClick={toggleHamburger} />
					<li className="c-menu-mb__item">
						<Link to={`${basePath}dashboard`}>
							<img src={DashboardIcon} alt="dashboard" />
							<p className="c-menu-mb__text">Dashboard</p>
						</Link>
					</li>
					<li className="c-menu-mb__item">
						<Link to={`${basePath}settings`}>
							<img src={SettingsIcon} alt="settings" />
							<p className="c-menu-mb__text">Settings</p>
						</Link>
					</li>
					<li className="c-menu-mb__item">
						<Link to="#" onClick={logout}>
							<p className="c-menu-mb__text">Logout</p>
						</Link>
					</li>
				</ul>
			</div>
		</Fragment>
	);
};

Menu.defaultProps = {
	active: "",
	basePath: "",
};

export default withRouter(Menu);
