import React, { Fragment, lazy, Suspense } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import history from "../common/helper/history";
import PrivateRoute from "./privateRoutes";
import PublicRoute from "./publicRoutes";

import { AlertPopup } from "../components";
import { PrivacyPolicy, ToS } from "../views/user/terms/terms";
const Landing = lazy(() => import("../views/user/landing/landing"));
const LuckyDrawAnnouncement = lazy(() => import("../views/user/lucky-draw-announcement/lucky-draw-announcement"));
const Results = lazy(() => import("../views/user/results/results"));
const AdminLogin = lazy(() => import("../views/admin/login/login"));
const AdminDashboard = lazy(() => import("../views/admin/dashboard/dashboard"));
const AdminSettings = lazy(() => import("../views/admin/settings/settings"));
const AdminResetPassword = lazy(() => import("../views/admin/reset-password/reset-password"));
const AdminCommission = lazy(() => import("../views/admin/commission/commission"));
const AdminBonus = lazy(() => import("../views/admin/bonus/bonus"));
const AdminTransaction = lazy(() => import("../views/admin/transaction/transaction"));
const AdminAffiliates = lazy(() => import("../views/admin/affiliates/affiliates"));
const AffiliateLogin = lazy(() => import("../views/affiliate/login/login"));
const AffiliateRegister = lazy(() => import("../views/affiliate/register/register"));
const AffiliateCompleteRegistration = lazy(() => import("../views/affiliate/register/completeRegistration"));
const AffiliateDashboard = lazy(() => import("../views/affiliate/dashboard/dashboard"));
const AffiliateSettings = lazy(() => import("views/affiliate/settings/settings"));
const AffiliateProfileSettings = lazy(() => import("views/affiliate/settings/profileSettings"));
const AffiliateDomainSettings = lazy(() => import("views/affiliate/settings/domainSettings"));

const Routes = () => {
	return (
		<Fragment>
			<AlertPopup />
			<Router history={history}>
				<Suspense fallback={<div></div>}>
					<Switch>
						<Route exact path="/" component={Landing} />
						{/* <Route exact path="/code=:code" component={Landing} /> */}
						<Route exact path="/results" component={Results} />
						<Route exact path="/code=:code/results" component={Results} />
						<Route exact path="/rewards" component={LuckyDrawAnnouncement} />
						<Route exact path="/reset-password" component={AdminResetPassword} />
						<Route exact path="/terms" component={ToS} />
						<Route exact path="/policy" component={PrivacyPolicy} />

						<PrivateRoute exact path="/admin/dashboard" component={AdminDashboard} />
						<PrivateRoute exact path="/admin/settings/commission" component={AdminCommission} />
						<PrivateRoute exact path="/admin/settings/bonus" component={AdminBonus} />
						<PrivateRoute exact path="/admin/settings" component={AdminSettings} />
						<PrivateRoute exact path="/admin/affiliates" component={AdminAffiliates} />
						<PrivateRoute exact path="/admin/transaction/:refId" component={AdminTransaction} />
						<PrivateRoute exact path="/admin/affiliate/profile/:userId" component={AffiliateProfileSettings} />
						<PrivateRoute exact path="/admin/affiliate/subdomain/:userId" component={AffiliateDomainSettings} />
						<PrivateRoute exact path="/admin/affiliate/:userId" component={AffiliateSettings} />
						<PrivateRoute exact path="/admin/reports" component={AffiliateDashboard} />

						<PrivateRoute exact path="/affiliate/dashboard" component={AffiliateDashboard} />
						<PrivateRoute exact path="/affiliate/settings/profile" component={AffiliateProfileSettings} />
						<PrivateRoute exact path="/affiliate/settings/subdomain" component={AffiliateDomainSettings} />
						<PrivateRoute exact path="/affiliate/settings" component={AffiliateSettings} />
						<PublicRoute exact path="/affiliate/register/complete" component={AffiliateCompleteRegistration} />
						<PublicRoute exact path="/affiliate/register/apiKey=:apiKey&name=:name&email=:email&token=:token&step=verifyEmail" component={AffiliateRegister} />
						<PublicRoute exact path="/affiliate/register" component={AffiliateRegister} />

						<PublicRoute exact path="/admin" component={AdminLogin} />
						<PublicRoute exact path="/affiliate" component={AffiliateLogin} />

						<Redirect path="/admin/**" to="/admin" />
						<Redirect path="/affiliate/**" to="/affiliate" />
						<Redirect path="**" to="/" />
					</Switch>
				</Suspense>
			</Router>
		</Fragment>
	);
};

export default Routes;
