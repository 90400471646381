import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import dateRangeListActions from "../actions/date-range-list-actions";

function* getDateRangeList(action) {
    try {
        const response = yield call(apiRequest.admin.get.dateRanges, action.data);
        yield put({ type: dateRangeListActions.UPDATE_DATE_RANGE, data: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* dateRangelListSaga() {
    yield takeLatest(dateRangeListActions.GET_DATE_RANGE, getDateRangeList);
}

export default dateRangelListSaga;
