import userActions from "../actions/user-actions";

const initialState = {
    token: "",
    profile: {},
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActions.UPDATE_USER_CREDENTIALS:
            return {
                ...state,
                ...action.data,
            };
        case userActions.UPDATE_USER_PROFILE:
            return {
                ...state,
                profile: { ...action.data.profile },
            };
        default:
            return state;
    }
};

export default userReducer;
