import React, { Fragment, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerInput = (props) => {
    const inputClasses = classNames({
        "c-date-picker": true,
        "c-date-picker--disabled": props.disabled,
        "c-date-picker--invalid": props.invalid,
    });
    const [date, setDate] = useState("");
    const ErrorMessage = () => {
        const errorMessage = props.invalid && props.errorMessage ? props.errorMessage : "";
        return <p className="c-date-picker__error-msg">{errorMessage}</p>;
    };

    const selectDate = (date) => {
        setDate(date);
        props.onSelect(date);
    };

    return (
        <Fragment>
            {props.label && props.labelOut &&
            <Label className="c-date-picker__label mb-2" for={props.label.toLowerCase()}>
                {props.label}
            </Label>}
            <FormGroup className={inputClasses}>
                {props.label && !props.labelOut &&
                <Label className="c-date-picker__label" for={props.label.toLowerCase()}>
                    {props.label}
                </Label>}
                <DatePicker
                    id={props.label.toLowerCase()}
                    ref={props.inputRef}
                    disabled={props.disabled}
                    name={props.name}
                    selected={props.value ?? date}
                    className="c-date-picker__input"
                    placeholderText={props.placeholder}
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    onSelect={selectDate}
                    maxDate={new Date()}
                    onBlur={props.onBlur}
                    dateFormat="dd-MM-yyyy"
                />
                {props.icon &&
                <img className="c-date-picker__icon" src={props.icon} alt="icon" />}
            </FormGroup>
            {!props.hideError &&
            <ErrorMessage />}
        </Fragment>
    );
};

DatePickerInput.defaultProps = {
    label: null,
    type: "text",
    name: "input",
    placeholder: "",
    value: null,
    onSelect: () => {},
    onBlur: () => {},
    icon: null,
    labelOut: false,
    hideError: false
};

export default DatePickerInput;
