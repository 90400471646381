const reportConversionActions = {
    GET_REPORT_CONVERSION: 'GET_REPORT_CONVERSION',
    UPDATE_REPORT_CONVERSION: 'UPDATE_REPORT_CONVERSION',
    STORE_REPORT_CONVERSION_PARAMS: 'STORE_REPORT_CONVERSION_PARAMS',

    GET_ONE_REPORT_CONVERSION: "GET_ONE_REPORT_CONVERSION",
    UPDATE_ONE_REPORT_CONVERSION: "UPDATE_ONE_REPORT_CONVERSION",
    
    REPORT_CONVERSION_IS_LOADING: 'REPORT_CONVERSION_IS_LOADING',
    REPORT_CONVERSION_LOAD_ERROR: 'REPORT_CONVERSION_LOAD_ERROR',
};

export default reportConversionActions;