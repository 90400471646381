import industryListActions from "../actions/industry-list-actions";

const initialState = {};

const industryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case industryListActions.UPDATE_INDUSTRY_LIST:
            const { industries } = action.data;
            const data = industries.reduce ( ( result, item, index ) => {
                result[index] = {
                    value: item.name,
                    label: item.name
                };
                return result;
            }, [] );
            return { data };
        default:
            return state;
    }
};

export default industryListReducer;