const affiliateActions = {
    AFFILIATE_PROFILE_NOT_READY: "AFFILIATE_PROFILE_NOT_READY",
    GET_AFFILIATE_PROFILE: "GET_AFFILIATE_PROFILE",
    UPDATE_AFFILIATE_PROFILE: "UPDATE_AFFILIATE_PROFILE",

    GET_AFFILIATE_PROFILE_PIC: "GET_AFFILIATE_PROFILE_PIC",
    UPDATE_AFFILIATE_PROFILE_PIC: "UPDATE_AFFILIATE_PROFILE_PIC",

    GET_AFFILIATE_LOGO_PIC: "GET_AFFILIATE_LOGO_PIC",
    UPDATE_AFFILIATE_LOGO_PIC: "UPDATE_AFFILIATE_LOGO_PIC",

    GET_AFFILIATE_BANNER_PIC: "GET_AFFILIATE_BANNER_PIC",
    UPDATE_AFFILIATE_BANNER_PIC: "UPDATE_AFFILIATE_BANNER_PIC",
};

export default affiliateActions;