import reportConversionActions from "../actions/report-conversion-actions";

const initialState = {
    isLoading: false,
    error: null,
    data: null,
    activeData: null
};

const reportConversionReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportConversionActions.UPDATE_REPORT_CONVERSION:
        case reportConversionActions.UPDATE_ONE_REPORT_CONVERSION:
        case reportConversionActions.STORE_REPORT_CONVERSION_PARAMS:
        case reportConversionActions.REPORT_CONVERSION_IS_LOADING:
        case reportConversionActions.REPORT_CONVERSION_LOAD_ERROR:
            return {
                ...state,
                ...action.data,
            };

        default:
            return state;
    }
};

export default reportConversionReducer;