import { all } from "redux-saga/effects";
import userSaga from "./user-saga";
import insurersSaga from "./insurers-saga";
import usersListSaga from "./users-list-saga";
import carsMakeListSaga from "./cars-make-list-saga";
import carsModelListSaga from "./cars-model-list-saga";
import dateRangelListSaga from "./date-range-list-saga";
import affiliateSaga from "./affiliate-saga";
import bankListSaga from './bank-list-saga';
import industryListSaga from './industry-list-saga';
import countryListSaga from './country-list-saga';
import stateListSaga from './state-list-saga';
import affiliateListSaga from "./affiliate-list-saga";
import commissionSaga from "./commission-saga";
import bonusSaga from "./bonus-saga";
import reportSummarySaga from "./report-summary-saga";
import reportConversionSaga from "./report-conversion-saga";
import reportMarginSaga from "./report-margin-saga";
import registerSaga from './register-saga';

export default function* rootSaga() {
    yield all([
        userSaga(), 
        insurersSaga(), 
        usersListSaga(), 
        carsMakeListSaga(), 
        carsModelListSaga(), 
        dateRangelListSaga(),
        affiliateSaga(),
        bankListSaga(),
        industryListSaga(),
        countryListSaga(),
        stateListSaga(),
        affiliateListSaga(),
        commissionSaga(),
        bonusSaga(),
        reportSummarySaga(),
        reportConversionSaga(),
        reportMarginSaga(),
        registerSaga(),
    ]);
}
