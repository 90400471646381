import moment from "moment";
import { call, put, takeLatest } from "redux-saga/effects";

import apiRequest from "../../common/services/api";
import reportConversionActions from "../actions/report-conversion-actions";

function* isLoading(isLoading) {
    yield put({
        type: reportConversionActions.REPORT_CONVERSION_IS_LOADING, data: { isLoading }
    });
}

function* getReportConversion({ isAdmin, startDate, endDate }) {
    try {
        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');

        yield call(isLoading, true);
        const response = yield call(apiRequest[isAdmin ? 'admin' : 'affiliate'].get.getReportConversion, startDate, endDate);
        yield put({ 
            type: reportConversionActions.UPDATE_REPORT_CONVERSION, 
            data: { data: response.data } 
        });
        yield put({ 
            type: reportConversionActions.STORE_REPORT_CONVERSION_PARAMS, 
            data: { startDate, endDate } 
        });
        yield call(isLoading, false);
    } catch ( error ) {
        console.log ( error );
        yield call(isLoading, false);
        yield put({
            type: reportConversionActions.REPORT_CONVERSION_LOAD_ERROR, 
            data: { error }
        });
    }
}

function* getOneReportConversion({ reportId }) {
    try {
        yield call(isLoading, true);
        const response = yield call(apiRequest.admin.get.getOneReportConversion, reportId);
        yield put({ 
            type: reportConversionActions.UPDATE_ONE_REPORT_CONVERSION, 
            data: { activeData: response.data } 
        });
        yield call(isLoading, false);
    } catch ( error ) {
        console.log ( error );
        yield call(isLoading, false);
        yield put({
            type: reportConversionActions.REPORT_CONVERSION_LOAD_ERROR, 
            data: { error }
        });
    }
}

function* reportConversionSaga() {
    yield takeLatest(reportConversionActions.GET_REPORT_CONVERSION, getReportConversion);
    yield takeLatest(reportConversionActions.GET_ONE_REPORT_CONVERSION, getOneReportConversion);
}

export default reportConversionSaga;