import { combineReducers } from "redux";
import userActions from "../actions/user-actions";
import alertReducer from "./alert-reducer";
import userReducer from "./user-reducer";
import registerReducer from "./register-reducer";
import insurersReducer from "./insurers-reducer";
import usersListReducer from "./users-list-reducer";
import carsMakeListReducer from "./cars-make-list-reducer";
import carsModelListReducer from "./cars-model-list-reducer";
import dateRangeListReducer from "./date-range-list-reducer";
import affiliateReducer from "./affiliate-reducer";
import bankListReducer from "./bank-list-reducer";
import industryListReducer from "./industry-list-reducer";
import countryListReducer from "./country-list-reducer";
import stateListReducer from "./state-list-reducer";
import affiliateListReducer from './affiliate-list-reducer';
import documentReducer from './document-reducer';
import commissionReducer from './commission-reducer';
import bonusReducer from './bonus-reducer';
import reportSummaryReducer from "./report-summary-reducer";
import reportConversionReducer from "./report-conversion-reducer";
import reportMarginReducer from "./report-margin-reducer";

const appReducer = combineReducers({
    alert: alertReducer,
    user: userReducer,
    register: registerReducer,
    insurers: insurersReducer,
    usersList: usersListReducer,
    carsMakeList: carsMakeListReducer,
    carsModelList: carsModelListReducer,
    dateRangeList: dateRangeListReducer,
    affiliate: affiliateReducer,
    bankList: bankListReducer,
    industryList: industryListReducer,
    countryList: countryListReducer,
    stateList: stateListReducer,
    affiliateList: affiliateListReducer,
    document: documentReducer,
    commission: commissionReducer,
    bonus: bonusReducer,
    reportSummary: reportSummaryReducer,
    reportConversion: reportConversionReducer,
    reportMargin: reportMarginReducer,
});

const rootReducer = (state, action) => {
    if (action.type === userActions.USER_LOGOUT) {
        state = {};
    }
    return appReducer(state, action);
};

export { rootReducer };
