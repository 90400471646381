import reportSummaryActions from "../actions/report-summary-actions";

const initialState = {
    isLoading: false,
    error: null,
    data: null,
};

const reportSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportSummaryActions.UPDATE_REPORT_SUMMARY:
        case reportSummaryActions.REPORT_SUMMARY_IS_LOADING:
        case reportSummaryActions.REPORT_SUMMARY_LOAD_ERROR:
            return {
                ...state,
                ...action.data,
            };

        default:
            return state;
    }
};

export default reportSummaryReducer;