import apiRequest from "../../common/services/api";
import { store } from "../../redux/store/store";

const isLogin = async () => {
    const user = store.getState().user;
    const adminRole = user.profile.roleName === "Admin";
    let validToken = false;

    if (user && user.token) {
        try {
            if (adminRole) {
                await apiRequest.admin.get.profile();
            }
            else {
                await apiRequest.affiliate.get.getProfile();
            }
            validToken = true;
        } catch (error) {
            const status = error.response.status;
            if (status === 401) {
                validToken = false;
            }
        }
    }
    return validToken;
};

export default isLogin;
