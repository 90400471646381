import React, { useEffect, useState } from 'react';
import UserEmptyIcon from 'assets/images/user-empty-icon.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const UserProfile = props => {
    const classes = classNames({
        'm-0': true,
        'text-light': props.light,
    });
    const globalState = useSelector(state => state);
    const [imgSrc, setImgSrc] = useState(UserEmptyIcon);

    useEffect(() => {
        if (props.profilePhotoId && globalState.document.documents) {
            if (props.profilePhotoId in globalState.document.documents) {
                setImgSrc(
                globalState.document.documents[props.profilePhotoId]
                );
            }
        }
    }, [props.profilePhotoId, globalState.document.documents]);
    
    return (
        <div className="d-flex align-items-center w-100">
            <img 
                className="mr-2" 
                src={props.profilePhotoURL ?? imgSrc} 
                width={32} 
                height={32} 
                style={{
                    borderRadius: '100px',
                    objectFit: 'cover'
                }}
                alt="profile"
            />
            {props.link ?
            <a href={props.link}>
                {props.username}
            </a> :
            <p className={classes}>
                <b>{props.username}</b>
            </p>}
        </div>
    );
};

UserProfile.defaultProps = {
    profilePhotoId: null,
    profilePhotoURL: null,
    username: 'Alterflex',
};

export default UserProfile;