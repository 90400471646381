import carsMakeListActions from "../actions/cars-make-list-actions";

const initialState = {};

const carsMakeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case carsMakeListActions.UPDATE_CARS_MAKE_LIST:
            return { ...action.data };
        default:
            return state;
    }
};

export default carsMakeListReducer;
